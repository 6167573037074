

import FETCHING from  '../../library/fetching'
import UMUM from  '../../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        sub_unit_kerja_id  : '',
        m_jenis_barang_id   : '',
        register  : '',
        luas  : '',
        tahun  : '',
        alamat  : '',
        hak  : '',
        tgl  : '',
        no  : '',
        penggunaan  : '',
        m_asal_usul_id   : '',
        harga  : '',
        keterangan  : '',
        createdAt  : '',
        createdBy  : '',
        
      },

      filterku : {
        tahun : '',
        sub_unit_kerja_id : '',
      },

      
     

      uraian : {
        sub_unit_kerja_uraian : '',
        jenis_barang_uraian : '',
        jenis_barang_kode : '',
        asal_usul_uraian : '',
      },
     
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 8,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_view : false,
      mdl_koordinat : false,
      mdl_add_koordinat : false,



      btn_add: false,

      FETCHING : FETCHING,
      UMUM : UMUM,

    }
  },
  methods: {


    getView : async function(){
      this.$store.commit("shoWLoading");
      var body = {
          data_ke: this.page_first,
          cari_value: this.cari_value,
          page_limit : this.page_limit,
          sub_unit_kerja_id : this.filterku.sub_unit_kerja_id,
      }

      var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_KIB_A + "view", body)
      console.log(data);
      this.list_data = data.data;
      this.page_last = data.jmlData;
      this.$store.commit("hideLoading");

    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_KIB_A + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },

    editData : function(){
      fetch(this.$store.state.url.URL_KIB_A + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_KIB_A + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData : function(data){
        this.tunggu('','', data.sub_unit_kerja_id);

        this.form.id = data.id;
        this.form.sub_unit_kerja_id  = data.sub_unit_kerja_id;
        this.form.m_jenis_barang_id   = data.m_jenis_barang_id;
        this.form.register  = data.register;
        this.form.luas  = data.luas;
        this.form.tahun  = data.tahun;
        this.form.alamat  = data.alamat;
        this.form.hak  = data.hak;
        this.form.tgl  = data.tgl;
        this.form.no  = data.no;
        this.form.penggunaan  = data.penggunaan;
        this.form.m_asal_usul_id   = data.m_asal_usul_id;
        this.form.harga  = data.harga;
        this.form.keterangan  = data.keterangan;
        this.form.createdAt  = data.createdAt;
        this.form.createdBy  = data.createdBy;


          this.uraian.sub_unit_kerja_uraian = data.sub_unit_kerja_uraian;
          this.uraian.jenis_barang_uraian = data.jenis_barang_uraian;
          this.uraian.jenis_barang_kode = data.jenis_barang_kode;
          this.uraian.asal_usul_uraian = data.asal_usul_uraian;
       
    },



    

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        tunggu : async function(aa,bb, cc){
          this.$store.state.listAsalUsul = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_ASAL_USUL + "list", {data : aa})
          this.$store.state.listJenisBarang = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_JENIS_BARANG + "list", {data : bb})
          this.$store.state.listSubUnitKerja = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_SUB_UNIT_KERJA + "autocomplete", {cari : cc})
        },


        filterUnitKerja : function (val, update, abort) {
            update(async () => {
              console.log(val);
              if (val === '') {}
              else {
                this.$store.state.listSubUnitKerja = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_SUB_UNIT_KERJA + "autocomplete", {cari : val})
              }
            })
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    this.tunggu();
    

    this.getView();
  },
}
